import "./App.css";

function App() {
  const hostname = window.location.hostname;
  const subdomain = hostname.split(".")[0];
  document.title = `${subdomain}`;
  return (
    // <div className="App">
    //   <header className="App-header">
    //     <h1>{subdomain} Lead Gen Page</h1>
    //   </header>
    // </div>
    <div
      dangerouslySetInnerHTML={{
        __html: `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <meta http-equiv="X-UA-Compatible" content="ie=edge" />
    <title>${subdomain}</title>
    <style>
        * {
            margin: 0;
            padding: 0;
            box-sizing: border-box;
        }
        body {
            font-family: Arial, sans-serif;
            background-color: #121212;
            color: #fff;
        }
        .navbar {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            background-color: #333;
            padding: 15px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            z-index: 10;
        }
        .navbar img {
            height: 40px;
        }
        .navbar button {
            padding: 10px 20px;
            font-size: 1rem;
            background-color: #ff6f61;
            color: #fff;
            border: none;
            cursor: pointer;
            border-radius: 5px;
            transition: background-color 0.3s ease;
        }
        .navbar button:hover {
            background-color: #ff4f3f;
        }
        .hero {
            height: 100vh;
            background: linear-gradient(135deg, #004d67, #000000);
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            color: #fff;
            padding: 0 20px;
            margin-top: 70px;
        }
        .hero h1 {
            font-size: 3rem;
            margin-bottom: 1rem;
        }
        .hero p {
            font-size: 1.25rem;
            margin-bottom: 2rem;
        }
        .cta-button {
            padding: 12px 30px;
            font-size: 1.25rem;
            background-color: #ff6f61;
            color: #fff;
            border: none;
            cursor: pointer;
            border-radius: 5px;
            transition: background-color 0.3s ease;
        }
        .cta-button:hover {
            background-color: #ff4f3f;
        }
        .clients-logos {
            display: flex;
            justify-content: center;
            gap: 2rem;
            margin: 4rem 0;
            flex-wrap: wrap;
            padding: 0 20px;
        }
        .client-logo {
            width: 120px;
            height: auto;
        }
        .lead-form-section {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding: 50px 20px;
            background-color: #1f1f1f;
            text-align: center;
        }
        .lead-form h2 {
            font-size: 2rem;
            margin-bottom: 1rem;
        }
        .lead-form {
            display: flex;
            flex-direction: column;
            width: 100%;
            max-width: 600px; /* Adjust max width */
            margin: 0 auto;
            align-items: center;
        }
        .lead-form input,
        .lead-form textarea {
            width: 80%;
            padding: 10px;
            margin: 10px 0;
            border-radius: 5px;
            border: 1px solid #ddd;
            background-color: #333;
            color: #fff;
        }
        .lead-form button {
            padding: 12px 30px;
            background-color: #00c6ff;
            color: #fff;
            border: none;
            cursor: pointer;
            border-radius: 5px;
            font-size: 1rem;
            width: 80%; /* Make the button the same width as inputs */
        }
        .lead-form button:hover {
            background-color: #0072ff;
        }
        .scroll-down {
            position: absolute;
            bottom: 20px;
            font-size: 2rem;
            cursor: pointer;
            animation: bounce 2s infinite;
        }
        @keyframes bounce {
            0%, 100% {
                transform: translateY(0);
            }
            50% {
                transform: translateY(-10px);
            }
        }

        /* Mobile responsiveness */
        @media screen and (max-width: 768px) {
            .hero h1 {
                font-size: 2.5rem;
            }
            .hero p {
                font-size: 1.1rem;
            }
            .cta-button {
                font-size: 1rem;
                padding: 10px 20px;
            }
            .clients-logos {
                flex-direction: column;
                gap: 1.5rem;
            }
            .lead-form input,
            .lead-form textarea {
                width: 90%;
            }
        }

        @media screen and (max-width: 480px) {
            .hero h1 {
                font-size: 2rem;
            }
            .hero p {
                font-size: 1rem;
            }
            .cta-button {
                font-size: 1rem;
                padding: 10px 20px;
            }
        }
    </style>
</head>
<body>
    <!-- Navbar -->
    <div class="navbar">
        <img src="logo.png" alt="${subdomain} logo" />
        <button onclick="document.getElementById('lead-form').scrollIntoView({behavior: 'smooth'})">
            Contact Us
        </button>
    </div>

    <!-- Hero Section -->
    <div class="hero">
        <div>
            <h1>${subdomain}</h1>
            <p>Custom Android & iOS apps, cloud infrastructure, and tailored APIs for your business.</p>
            <button class="cta-button" onclick="document.getElementById('lead-form').scrollIntoView({behavior: 'smooth'})">
                Get Started
            </button>
        </div>
    </div>

    <!-- Scroll Down Indicator -->
    <div class="scroll-down" onclick="document.getElementById('lead-form').scrollIntoView({behavior: 'smooth'})">
        &#8595;
    </div>

    <!-- Clients Section -->
    <div class="clients-logos">
        <img src="client1-logo.png" alt="Client 1" class="client-logo" />
        <img src="client2-logo.png" alt="Client 2" class="client-logo" />
        <img src="client3-logo.png" alt="Client 3" class="client-logo" />
    </div>

    <!-- Lead Form Section -->
    <div class="lead-form-section" id="lead-form">
        <h2>Contact Us for Your Next Project</h2>
        <form class="lead-form">
            <input type="text" placeholder="Your Name" required />
            <input type="email" placeholder="Your Email" required />
            <textarea placeholder="Tell us about your project" required></textarea>
            <button type="submit">Submit</button>
        </form>
    </div>
</body>
</html>
`,
      }}
    ></div>
  );
}

export default App;
